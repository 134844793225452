<template>
<div>
  <BannerTop :topInfo="topInfo"></BannerTop>
  <ListBox :listInfo="listInfo"></ListBox>
<div class="tabStyles">
  <TabBox></TabBox>
</div>
</div>
</template>
<script>
import ListBox from "@/pages/home/comments/listBox/index.vue";
import BannerTop from "@/pages/home/comments/bannerTop/index.vue";
import TabBox from "@/pages/home/comments/TabBox/index.vue";
export default {
  name: "dmx",
  components: {TabBox, ListBox,BannerTop},
  data(){
    return {
        topInfo:{
          title:'大模型应用',
          description:'人工智能模型训练需要强大的计算力支撑，人工智能平台基础设施昂贵，平台搭建、维护耗时耗力，便捷的、随时可用的、高性价比的模型训练与调试平台成为人工智能发展的关键因素之一。',
          imgUrl:'https://30093654.s21i.faiusr.com/2/ABUIABACGAAglPuxqgYo3IaRrgQwgA849gQ.jpg.webp'
        },
        listInfo:[
          {
            id: '1',
            title: '方案亮点',
            description: '深入分子动力学、蛋白质结构预测，计算机辅助药物设计、生物结构测定、生物信息学等高算力应用场景，提供一站式平台服务，优化应用设计开发效率， 屏蔽底层IT技术细节，高效生命科研计算；最低的价格实现最高的计算效率；业务流全栈定制化服务。',
            en: 'SHINING POINT',
            imgUrl: 'https://30093654.s21i.faiusr.com/4/ABUIABAEGAAgn-_lqgYo2PGHzwcw9QM4uQI.png.webp'
          },
          {
            id: '2',
            title: '方案亮点',
            description: '深入分子动力学、蛋白质结构预测，计算机辅助药物设计、生物结构测定、生物信息学等高算力应用场景，提供一站式平台服务，优化应用设计开发效率， 屏蔽底层IT技术细节，高效生命科研计算；最低的价格实现最高的计算效率；业务流全栈定制化服务。',
            en: 'SHINING POINT',
            imgUrl: 'https://30093654.s21i.faiusr.com/4/ABUIABAEGAAgn-_lqgYo2PGHzwcw9QM4uQI.png.webp'
          },
          {
            id: '3',
            title: '方案亮点',
            description: '深入分子动力学、蛋白质结构预测，计算机辅助药物设计、生物结构测定、生物信息学等高算力应用场景，提供一站式平台服务，优化应用设计开发效率， 屏蔽底层IT技术细节，高效生命科研计算；最低的价格实现最高的计算效率；业务流全栈定制化服务。',
            en: 'SHINING POINT',
            imgUrl: 'https://30093654.s21i.faiusr.com/4/ABUIABAEGAAgn-_lqgYo2PGHzwcw9QM4uQI.png.webp'
          },
          {
            id: '4',
            title: '方案亮点',
            description: '深入分子动力学、蛋白质结构预测，计算机辅助药物设计、生物结构测定、生物信息学等高算力应用场景，提供一站式平台服务，优化应用设计开发效率， 屏蔽底层IT技术细节，高效生命科研计算；最低的价格实现最高的计算效率；业务流全栈定制化服务。',
            en: 'SHINING POINT',
            imgUrl: 'https://30093654.s21i.faiusr.com/4/ABUIABAEGAAgn-_lqgYo2PGHzwcw9QM4uQI.png.webp'
          }
        ]

    }
  }
}
</script>



<style scoped>
.tabStyles{
  width: 1200px;
  margin: 0 auto;
}
</style>