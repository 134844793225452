<template>
  <div>
    <banner-top :topInfo="topInfo"></banner-top>
    <div class="main">
      <div class="introduce">
        <div class="title">企业介绍</div>
        <div class="en">ABOUT</div>
      </div>
      <div class="content">
        <p>
          <span class="title"> 中关村数智人工智能产业联盟</span>是一家科技创业企业孵化器，依托中关村数智人工智能联盟院士专家服务中心、数据要素服务平台、人工智能大模型数据服务平台、中关村人工智能大模型应用人才培养基地等多项平台资源，以服务科技团队为主，立足战略性新兴产业新一代信息技术领域，以“硬空间+软平台”模式，面向广大高成长性科技企业提供创业空间、社交空间和资源共享空间，提供专业化、全面化、系统化的孵化服务。
        </p>
        <p>
          中关村数智人工智能产业联盟充分发挥平台自身的专家智库资源优势，为小微企业提供与院士、产业专家等学术大咖交流的机会；搭建与高校、科研院所沟通的平台，提供专家政策咨询、高新政策解读等对接。</p>
        <p>
          建立专利风险防范和互惠机制，建立公共专利池和与标准相关的知识产权政策，促进小微企业知识产权与研发生产基础设施的共享，开展共性关键技术的联合攻关。为企业提供人力资源、法律、知识产权、财务管理等多维度的企业管理相关支持。</p>
        <img src="https://30093654.s21i.faiusr.com/4/ABUIABAEGAAgm-_lqgYo_sD07wcwkAg41AI.png.webp" alt="">
      </div>
      <div class="introduce">
        <div class="title">员工风采</div>
        <div class="en">STAFF</div>
      </div>

      <div style="width:100%;max-width:1200px;display: flex">
        <div class="img-l">
          <div class="l-t">
            <img src="https://30093654.s21i.faiusr.com/2/ABUIABACGAAg2sWrqgYomMixATCgCzi4CA!400x400.jpg.webp" alt="">
            <img src="https://30093654.s21i.faiusr.com/2/ABUIABACGAAg2sWrqgYorY7uyQIwoAs4uAg!400x400.jpg.webp" alt="">
          </div>
          <img style="width: 780px;height: 467px;margin-top: 55px" src="https://30093654.s21i.faiusr.com/4/ABUIABAEGAAg2sWrqgYoxIeRqwUwoQY43wM!800x800.png.webp" alt="">
        </div>
        <div class="img-r">
          <img style="height: 467px;" src="https://30093654.s21i.faiusr.com/2/ABUIABACGAAg6unGqgYoz8CLGTC4CDizCg!500x500.jpg.webp" alt="">
          <img style="margin-top: 15px" src="https://30093654.s21i.faiusr.com/2/ABUIABACGAAg2sWrqgYo2LqitQEwuAg4gwg!400x400.jpg.webp" alt="">
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import BannerTop from "@/pages/home/comments/bannerTop/index.vue";

export default {
  name: "about",
  components: {BannerTop},
  data() {
    return {
      topInfo:{
        title:'关于我们',
        description:'走进开元云 | 加入开元云',
        imgUrl:'https://30093654.s21i.faiusr.com/2/ABUIABACGAAg-6_nqgYogO2FjgcwgAo4pAM.jpg.webp'
      },
    }
  }
}
</script>


<style scoped lang="scss">
.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 35px;
}

.introduce {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 25px 0;
}

.title {
  font-size: 26px;
}

.en {
  font-size: 16px;
  margin-top: 10px;
  color: rgb(169, 169, 169);
}

.content {
  width: 100%;
  max-width: 1200px;
  font-size: 16px;
  line-height: 1.5;
  color: #666666;

  img {
    width: 100%;
    margin: 15px 0;
  }

  .title {
    font-weight: bold;
    color: #4c4948;
    font-size: 16px;
  }
}
.img-l{
  display: flex;
  flex-direction: column;

  margin-right: 15px;
}
.img-r{
  display: flex;
  flex-direction: column;
  img{
    width: 360px;
  }
}
.l-t{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  img{
    width: 380px;
    height: 285px;
  }
}
</style>