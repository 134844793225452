<template>
  <div>
    <el-tabs class="tabStyles" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="item in tabList" :key="item.id" :label="item.label" :name="item.id">

        <img :src="item.imgUrl" alt="">
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  name: "tabBox",
  data(){
    return{
      activeName: '1',
      tabList:[
        {
          id:'1',
          label:'大模型应用1',
          imgUrl:'https://30093654.s21i.faiusr.com/4/ABUIABAEGAAgn-_lqgYo2PGHzwcw9QM4uQI.png.webp'
        },
        {
          id:'2',
          label:'大模型应用2',
          imgUrl:'https://30093654.s21i.faiusr.com/2/ABUIABACGAAglPuxqgYo3IaRrgQwgA849gQ.jpg.webp'
        },
        {
          id:'3',
          label:'大模型应用3',
          imgUrl:'https://30093654.s21i.faiusr.com/2/ABUIABACGAAg-6_nqgYogO2FjgcwgAo4pAM.jpg.webp'
        }
      ]
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style scoped>
.tabStyles{
  border:1px solid red;
}
.el-tab-pane{
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 800px;
    height: 650px;
  }
}
::v-deep .el-tabs__item{
  font-size: 25px!important;
  margin: 10px 0;
}
::v-deep .el-tabs__nav-scroll{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;


}
</style>